const addressForm = document.getElementById('address-form')

if (addressForm) {
  addressForm.noValidate = true

  addressForm.addEventListener('submit', (event) => {
    if (validate()) {
      return true
    } else {
      event.preventDefault()
    }
  })
}

const addError = (element) => {
  if (!element.classList.contains('error')) {
    element.addEventListener('change', validate)
    element.classList.add('input--error')
    element.parentNode.classList.add('input-wrapper--error')
  }
}

const removeError = (element) => {
  element.classList.remove('input--error')
  element.parentNode.classList.remove('input-wrapper--error')

  // element.removeEventListener("change", validate);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validate = () => {
  const twoWordsRegexp = /[a-zA-ZÆØÅæøå]+\s+[a-zA-ZÆØÅæøå]+/g
  const wordAndNumberRegexp = /(.*)+\s+[0-9]+/g

  document.querySelectorAll('#address-form [required]').forEach((input) => {
    if (input.getAttribute('type') === 'text') {
      if (!input.value) {
        addError(input)
      } else if (
        input.getAttribute('data-validation') == '2-words' &&
        !twoWordsRegexp.test(input.value)
      ) {
        addError(input)
      } else if (
        input.getAttribute('data-validation') == 'word-and-number' &&
        !wordAndNumberRegexp.test(input.value)
      ) {
        addError(input)
      } else {
        removeError(input)
      }
    } else if (input.getAttribute('type') === 'email') {
      if (!input.value || !validateEmail(input.value)) {
        addError(input)
      } else if (input.getAttribute('name') === 'email-repeat') {
        var emails = []
        document.querySelectorAll('input[type=email]').forEach((email) => {
          emails.push(email.value)
        })

        if (emails.every((val, i, arr) => val === arr[0])) {
          removeError(input)
        } else {
          addError(input)
        }
      } else {
        removeError(input)
      }
    } else if (input.getAttribute('type') === 'checkbox') {
      if (!input.checked) {
        addError(input)
      } else {
        removeError(input)
      }
    }
  })

  const errors = document.getElementsByClassName('input--error')

  if (errors.length) {
    errors[0].focus()
    return false
  } else {
    return true
  }
}
