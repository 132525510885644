const spinnerElement = document.getElementById('js-spinner')

if (spinnerElement) {
  new Vue({
    el: '#js-spinner',
    data: {
      name: '',
      email: '',
      accepted: false,
      submitted: false,
      degrees: 0,
      prize: 0,
      done: false,
      open: false,
    },
    methods: {
      submit(type, token, list_id) {
        this.degrees = 3600 + this.randomIntFromInterval(60, 360)

        setTimeout(() => {
          this.submitted = true
        })

        setTimeout(() => {
          this.done = true
        }, 8000)

        this.calculatePrize()

        const options = { method: 'GET', headers: { Accept: 'text/html' } }

        if (type === 'klaviyo') {
          const data = {
            token,
            properties: {
              $email: this.email,
              $first_name: this.name,
              $consent: ['email'],
            },
          }

          fetch(
            `https://a.klaviyo.com/api/identify?data=${btoa(
              unescape(encodeURIComponent(JSON.stringify(data)))
            )}`,
            options
          )
            .then((response) => response.json())
            .then(() => {
              const options = {
                method: 'POST',
                body: JSON.stringify({
                  profiles: [
                    {
                      email: this.email,
                      fortune_wheel: true,
                    },
                  ],
                }),
              }

              fetch(
                `https://northorganic.eu/api/klaviyo.php?url=${encodeURIComponent(
                  `https://a.klaviyo.com/api/v2/list/${list_id}/subscribe`
                )}&token=${token}`,
                options
              )
                .then((response) => response.json())
                .then((data) => {
                  console.log('Subscribed', data)
                })
            })
            .catch((err) => console.error(err))
        }
      },

      close() {
        this.open = false
        window.localStorage.setItem('closed_wheel', true)
      },

      calculatePrize() {
        const prizeDegree = this.degrees - 3600

        if (prizeDegree < 120) {
          this.prize = 1
        } else if (prizeDegree < 180) {
          this.prize = 2
        } else if (prizeDegree < 240) {
          this.prize = 3
        } else if (prizeDegree < 300) {
          this.prize = 4
        } else if (prizeDegree < 360) {
          this.prize = 5
        }

        window.localStorage.setItem('prize', this.prize)
      },

      randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
      },
    },

    mounted() {
      if (window.localStorage.getItem('prize')) {
        this.prize = parseInt(window.localStorage.getItem('prize'))

        if (this.prize === 1) {
          this.degrees = 90
        } else if (this.prize === 2) {
          this.degrees = 150
        } else if (this.prize === 3) {
          this.degrees = 210
        } else if (this.prize === 4) {
          this.degrees = 270
        } else if (this.prize === 5) {
          this.degrees = 330
        }

        this.submitted = true
        this.done = true
      } else {
        setTimeout(() => {
          if (!window.localStorage.getItem('closed_wheel')) {
            this.open = true
          }
        }, 5000)
      }
    },
  })
}
