const productSlider = document.getElementById("js-product-slider");

if(productSlider) {
   const productSliderFlkty = new Flickity('#js-product-slider', { 
         cellAlign: "left", 
         contain: true, 
         pageDots: false,
         imageLoaded: true,
         lazyLoad: true,
         wrapAround: true,
         draggable: false
   });

   const productImages = document.querySelectorAll(".product-page__image img");
   productImages.forEach((item) => {
      item.addEventListener("load", () => {
         imageZoom(item);
      })
   });

   const sliderButtons = document.querySelectorAll(`.product-page__slider-button`);
   sliderButtons.forEach((item) => {
      item.addEventListener("click", (event) => {
         productSliderFlkty.select(parseInt(item.getAttribute("data-slide")));
      });
   })
}

const productRelatedSlider = document.querySelectorAll(".js-product-related-slider");
if(productRelatedSlider) {
   productRelatedSlider.forEach((slider) => {
      new Flickity(slider, {
         cellAlign: "left", 
         contain: true, 
         pageDots: false,
         imageLoaded: true,
         lazyLoad: true,
         wrapAround: true,
         draggable: true
      })
   })
}

// Zoom functionality
function imageZoom(img) {
   var result = img.parentNode.querySelector(".product-page__zoom");

   /* Create lens: */
   var lens = document.createElement("DIV");
   lens.setAttribute("class", "product-page__zoom-lens");

   /* Insert lens: */
   img.parentElement.insertBefore(lens, img);

   /* Calculate the ratio between result DIV and lens: */
   var cx = result.offsetWidth / lens.offsetWidth;
   var cy = result.offsetHeight / lens.offsetHeight;

   /* Set background properties for the result DIV */
   result.style.backgroundImage = "url('" + img.src + "')";
   result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";

   /* Execute a function when someone moves the cursor over the image, or the lens: */
   img.addEventListener("mousemove", moveLens);

   /* And also for touch screens: */
   img.addEventListener("touchmove", moveLens);

   function moveLens(e) {

      var mq = window.matchMedia( "(max-width: 984px)" );
      if (mq.matches) {
         return false;
      }
      
     /* Prevent any other actions that may occur when moving over the image */
     e.preventDefault();

     /* Get the cursor's x and y positions: */
     var pos = getCursorPos(e);

     /* Calculate the position of the lens: */
     var x = pos.x - (lens.offsetWidth / 2);
     var y = pos.y - (lens.offsetHeight / 2);

     /* Prevent the lens from being positioned outside the image: */
     if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
     if (x < 0) {x = 0;}
     if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
     if (y < 0) {y = 0;}

     /* Set the position of the lens: */
     lens.style.left = x + "px";
     lens.style.top = y + "px";

     /* Display what the lens "sees": */
     result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
   }

   function getCursorPos(e) {
     var x = 0;
     var y = 0;
     var pageX = e.pageX;
     var pageY = e.pageY;

     e = e || window.event;

     /* Get the x and y positions of the image: */
     var a = img.getBoundingClientRect();

      /* If touch event, overwrite pageX/Y */
     if(!pageX) {
         pageX = e.changedTouches[e.changedTouches.length - 1].pageX;
         pageY = e.changedTouches[e.changedTouches.length - 1].pageY;
      }

     /* Calculate the cursor's x and y coordinates, relative to the image: */
     x = pageX - a.left;
     y = pageY - a.top;

     /* Consider any page scrolling: */
     x = x - window.pageXOffset;
     y = y - window.pageYOffset;

     return {x : x, y : y};
   }
 }

