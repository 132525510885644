const openMegaClass = "navigation__mega--open"
const menuFrontClass = "navigation__menu--front";
const menu = document.querySelector(".navigation__menu");

const isFront = !!document.querySelector(".navigation__menu--is-front");

const checkIfMenuEffect = () => {
   if(!isFront) return;

   const elDistanceToTop = menu.getBoundingClientRect().top
   if(elDistanceToTop < 1) {
      if(menu.classList.contains(menuFrontClass)) {
         menu.classList.remove(menuFrontClass)
      }
   } else {
      if(!menu.classList.contains(menuFrontClass) && !document.querySelectorAll(`.${openMegaClass}`).length) {
         menu.classList.add(menuFrontClass)
      }
   }
}

const closeOpenMenus = () => {
   const openMegas = document.querySelectorAll(`.${openMegaClass}`);
   openMegas.forEach((item) => {
      item.classList.remove(openMegaClass);
      item.removeEventListener("click", (event) => {
         event.stopPropagation();
      });
   })
   checkIfMenuEffect();
}

const megaTriggers = document.querySelectorAll('.js-navigation-trigger-mega').forEach((item) => {
   item.addEventListener("click", (event) => {

      if(!item.classList.contains(openMegaClass)) {
         event.preventDefault();
      } else {
         return;
      }

      closeOpenMenus();
      item.classList.add(openMegaClass);
      item.nextElementSibling.classList.add(openMegaClass);

      if(menu.classList.contains(menuFrontClass)) {
         menu.classList.remove(menuFrontClass);
      }
   })
})

window.addEventListener("click", (event) => {
   if(!event.target.classList.contains(openMegaClass)) {
      closeOpenMenus();
   }
})

const navtrigger = document.querySelector(".js-navigation-trigger-mobile");
if(navtrigger) {

   navtrigger.addEventListener("click", () => {
      navtrigger.classList.toggle("navigation__trigger--open");
      document.querySelector(".navigation__mobile").classList.toggle("navigation__mobile--open");
   })
}

window.addEventListener("scroll", () => {
   checkIfMenuEffect();
})

