const categoryMobileTextTrigger = document.getElementById(
  'js-category-mobile-text-trigger'
)
const categoryMobileTextContent = document.getElementById(
  'js-category-mobile-text'
)

if (categoryMobileTextTrigger) {
  categoryMobileTextTrigger.addEventListener('click', () => {
    categoryMobileTextTrigger.style.display = 'none'

    categoryMobileTextContent.classList.add('category__mobile-text--open')
  })
}
