const addToBasket = document.getElementById('js-add-to-basket')

if (addToBasket) {
  new Vue({
    el: addToBasket,
    data: {
      dropdownOpen: 0,
      variant: {},
      text: {},
      amount: 1,
      modalOpen: 0,
      needsVariant: true,
    },
    methods: {
      changeAmount(value) {
        const newAmount = parseInt(this.amount) + value
        if (newAmount > 0) {
          this.amount = newAmount
        }
      },
      handleSubmit(e) {
        if (this.needsVariant) {
          alert(e.target.innerText)
        }
      },
      select(value, text, number) {
        this.variant[number.toString()] = value
        this.text[number.toString()] = text
        this.dropdownOpen = 0

        setTimeout(() => {
          this.checkNeedsVariant()
        })
      },
      checkNeedsVariant() {
        let hasEmptyVariant = false
        document
          .querySelectorAll('.add-to-basket__select')
          .forEach((select) => {
            if (!select.value) {
              hasEmptyVariant = true
            }
          })

        this.needsVariant = hasEmptyVariant
      },
      toggleDropdown(number) {
        if (this.dropdownOpen == number) {
          this.dropdownOpen = 0
        } else {
          this.dropdownOpen = number
        }
      },
    },
  })
}
