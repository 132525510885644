const searchElements = document.getElementsByClassName("js-search");

if(searchElements.length) {
   new Vue({
      el: '.js-search',
      data: {
          searchOpen: false,
          loading: false,
          debouncedInput: '',
          results: []
      },
      methods: {
        toggleSearch() {
            this.searchOpen = !this.searchOpen;

            setTimeout(() => {
                if(this.searchOpen) {
                    const element = this.$el.querySelector(".input");
                    element.focus();
                }
            });
        },
        search(query) {
            this.loading = true;

            fetch(`/ajax_search?term=${query}`)
            .then(response => response.json())
            .then(data => {
                this.results = data;
                this.loading = false;
            });
        },
        close() {
            this.searchOpen = false;
        }
      },
      watch: {
        debouncedInput: function(newValue, oldValue) {
            this.search(newValue);
        }
      },
      computed: {
        query: {
           get() {
             return this.debouncedInput
           },
           set(val) {
             if (this.timeout) clearTimeout(this.timeout)
             this.timeout = setTimeout(() => {
               this.debouncedInput = val
             }, 300)
           }
         }
       }
    });
}