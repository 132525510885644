;(function () {
   // Credits: https://www.seancdavis.com/blog/lazy-load-images-intersection-observer-api/
   const onIntersect = (entries) => {
      entries.forEach((entry) => {
         if (!entry.target.getAttribute("data-lazy-load") || !entry.isIntersecting) {
            return;
         }
         entry.target.setAttribute("src", entry.target.getAttribute("data-lazy-load"))

         entry.target.addEventListener("load", () => {
            entry.target.removeAttribute("data-lazy-load")
         })
      })
  }

  const observer = new IntersectionObserver(onIntersect)

  document.querySelectorAll("[data-lazy-load]").forEach(function (img) {
    observer.observe(img)
  })
})()