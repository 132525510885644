const ajaxList = document.getElementById('js-ajax-list')

if (ajaxList) {
  const pagesize = 12

  new Vue({
    el: '#js-ajax-list',
    data: {
      products: [],
      filters: [],
      brands: [],
      stockFilters: [],
      page: 0,
      hasMoreProducts: false,
      loading: false,
      openDropdown: null,
      filtersOpen: false,
      disableRequests: false,
      sort: 'weight',
      sortOrder: 'asc',
      sortLabel: '',
    },
    methods: {
      toggleFilters() {
        this.filtersOpen = !this.filtersOpen
      },
      toggleDropdown(key) {
        if (key == this.openDropdown) {
          this.openDropdown = null
        } else {
          this.openDropdown = key
        }
      },
      toggleFilter($event, tag, label, attribute, stock) {
        if ($event.target.checked) {
          if (stock) {
            this.stockFilters.push({ tag, label, attribute })
          } else if (attribute === 'brand') {
            this.brands.push({ tag, label, attribute })
          } else {
            this.filters.push({ tag, label, attribute })
          }
          this.getProducts(true)
        } else {
          this.filters = this.filters.filter((x) => x.tag !== tag)
          this.brands = this.brands.filter((x) => x.tag !== tag)
          this.stockFilters = this.stockFilters.filter((x) => x.tag !== tag)
          this.getProducts(true)

          document.getElementById(`filter-${tag}`).checked = false
        }
      },

      setSorting(sort, sortOrder, sortLabel) {
        this.sort = sort
        this.sortOrder = sortOrder
        this.sortLabel = sortLabel

        this.openDropdown = null

        this.getProducts(true)
      },

      resetFilters() {
        this.disableRequests = true
        document
          .querySelectorAll('#js-ajax-list input[type=checkbox]')
          .forEach((input) => {
            var event = new Event('change')
            input.checked = false
            input.dispatchEvent(event)
          })

        setTimeout(() => {
          this.disableRequests = false
          this.getProducts(true)
        })
      },

      getProducts(reset) {
        if (this.disableRequests) {
          return false
        }

        this.loading = true

        if (reset) {
          this.page = 0
        }

        const url = new URL(`${window.location.origin}/ajax`)

        const params = {
          limit: pagesize + 1,
          offset: this.page * pagesize,
          brands: this.brands.map((x) => x.tag).join('|'),
          attribute_tags_in_stock: this.stockFilters
            .map((x) => x.tag)
            .join('|'),
          // attribute_tags: this.filters.map((x) => x.tag).join('|'),
          atags: Object.keys(this.groupedFilters)
            .map((x) => this.groupedFilters[x].map((x) => x.tag).join('|'))
            .join(','),
          sort: this.sort,
          sort_order: this.sortOrder,
        }

        const documentElement = document.getElementById('js-category')
        const landingPageElement = document.getElementById('js-landing-page')
        if (documentElement) {
          params.categories = documentElement.getAttribute('data-id')
        } else if (landingPageElement) {
          params.landing_pages = landingPageElement.getAttribute('data-id')
        }

        Object.keys(params).forEach((key) => {
          if (params[key]) {
            url.searchParams.append(key, params[key])
          }
        })

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.length === pagesize + 1) {
              data.pop()
              this.hasMoreProducts = true
            } else {
              this.hasMoreProducts = false
            }

            if (reset) {
              this.products = [...data]
            } else {
              this.products = [...this.products, ...data]
            }

            this.loading = false
          })
      },
      hasCount(variants) {
        return variants.filter((x) => parseInt(x.cnt) > 0)
      },
      getPercentSaved(before, now) {
        return (((before - now) / before) * 100).toFixed(0)
      },
      getThumbnail(thumbnail) {
        return thumbnail.replace('200x200', '400x400')
      },
      nextPage() {
        this.page = this.page + 1

        this.getProducts()
      },
    },
    computed: {
      allFilters() {
        return [...this.filters, ...this.stockFilters, ...this.brands]
      },
      groupedFilters() {
        return this.filters.reduce((groups, item) => {
          const group = groups[item.attribute] || []
          group.push(item)
          groups[item.attribute] = group
          return groups
        }, {})
      },
    },
    created() {
      this.getProducts()
    },
  })
}
