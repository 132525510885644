// function onlyUnique(value, index, self) {
//   return self.indexOf(value) === index;
// }

// const products = document.querySelectorAll(".inner-product");
// const filterElement = document.getElementById("js-filters");

// if(filterElement) {
//    const excludedFilters = filterElement.getAttribute("data-excluded-filters").split(",");

//    new Vue({
//       el: '#js-filter-products',
//       data: {
//          filtersOpen: false,
//          filters: {},
//          activeFilters: [],
//          products: {},
//          generated: false,
//          activeCheckboxes: [],
//          openDropdown: null
//       },
//       methods: {
//          toggleFilters() {
//             this.filtersOpen = !this.filtersOpen;
//          },
//          toggleDropdown(key) {
//             if(key == this.openDropdown) {
//                this.openDropdown = null;
//             } else {
//                this.openDropdown = key;
//             }
//          },
//          activeOptions(key) {
//             const test = this.filters[key].filter((option) => {
//                return !!this.filterCount(key, option);
//             });

//             return test;
//          },
//          capitalizeFirstLetter(string) {
//             return string.charAt(0).toUpperCase() + string.slice(1);
//          },
//          resetFilters() {
//             document.querySelectorAll("#js-filters input[type=checkbox]").forEach((input) => {
//                var event = new Event('change');
//                input.checked = false;
//                input.dispatchEvent(event);
//             })
//          },
//          toggleFilter($event, key, option) {
//             if($event.target.checked) {
//                this.activeFilters[key].push(option);
//             } else {
//                this.activeFilters[key] = this.activeFilters[key].filter((x) => x !== option);
//                document.getElementById(`filter-${key}${option}`).checked = false;
//             }
//          },
//          isVisible(id, filters) {
//             const product = this.products[id];
//             let result = true;
//             let usedFilters = {...this.activeFilters};

//             if(filters) {
//                usedFilters = filters;
//             }

//             const activeKeys = Object.keys(usedFilters).filter((key) => usedFilters[key].length);

//             if(activeKeys.length) {
//                activeKeys.forEach((key) => {
//                   if(!product[key].some(x=> usedFilters[key].includes(x))) {
//                      result = false;
//                   }
//                });
//             }

//             return result;
//          },
//          filterCount(filter, value) {
//             let usedFilters = { ...this.activeFilters };
//             usedFilters[filter] = [value]

//             return Object.keys(this.products).filter((key) => {
//                if(this.isVisible(key, usedFilters)) {
//                   return true;
//                }
//                return false;

//             }).length
//          },
//          generateFilters() {
//             this.filters = {};
//             this.activeCheckboxes = [];

//             Object.keys(this.products).forEach((key) => {

//                if(this.isVisible(key)) {

//                   const product = this.products[key];

//                   // Remove excluded filters
//                   const attributeFilters = Object.keys(product).filter((attribute) => {
//                      return !excludedFilters.includes(attribute);
//                   });

//                   // Put all options into the respective key on the filters objects
//                   attributeFilters.forEach((attribute) => {
//                      const values = product[attribute];
//                      const currentValues = this.filters[attribute] || [];
//                      const newValues = [...currentValues, ...values].filter(onlyUnique);
//                      this.filters[attribute] = newValues;
//                   })
//                }

//             })

//             const emptyFilters = {...this.filters};
//             Object.keys(emptyFilters).map((key) => {
//                emptyFilters[key] = []
//             });

//             this.activeFilters = { ...emptyFilters };

//             // Remove filters that only have 1 option
//             Object.keys({...this.filters}).map((key) => {
//                if(this.filters[key].length <= 1) {
//                   delete this.filters[key]
//                }
//             });

//             setTimeout(() => {
//                this.generated = true;
//             })
//          },
//          generateProducts() {
//             products.forEach((product) => {
//                this.products[product.getAttribute("data-id")] = {};
//                const filters = JSON.parse(product.querySelector(".js-filter-attributes").innerHTML)

//                Object.keys(filters).forEach((key) => {
//                   const values = filters[key].split("|").filter(x => x !== "");
//                   this.products[product.getAttribute("data-id")][key] = values;
//                })
//             })
//          },
//       },
//       // watch: {
//       //    activeFilters: {
//       //       deep: true,
//       //       handler(value) {
//       //          const url = new URL(window.location.href);
//       //          Object.keys(value).filter(key => value[key].length).map((key) => {
//       //           url.searchParams.set(key, value[key].join("|"));
//       //          });
//       //          window.history.replaceState(null, null, url);
//       //       }
//       //    }
//       // },
//       created() {
//          this.generateProducts();
//          this.generateFilters();
//       }
//    })
// }
