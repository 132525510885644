const modalClosers = document.querySelectorAll(".js-close-modal");

if(modalClosers.length) {
    modalClosers.forEach((item) => {
        item.addEventListener("click", () => {
            const openModals = document.querySelectorAll(".modal--open");
            openModals.forEach((modal) => {
                modal.classList.remove("modal--open");
            })
        })
     });
}

const modalOpeners = document.querySelectorAll(".js-open-modal");
if(modalOpeners.length) {
    modalOpeners.forEach((item) => {
        item.addEventListener("click", () => {
            document.getElementById(item.getAttribute("data-id")).classList.add("modal--open");
        })
     });
}
