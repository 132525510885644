const ratings = document.querySelectorAll(".js-rating");

if(ratings.length) {

   ratings.forEach(el => {
      new Vue({
         el,
         data: {
            score: 0,
         },
         methods: {
           setScore(score, id) {
              this.score = parseInt(score);
              const input = document.getElementById("rating_" + id);
              input.value = score;
              var event = new Event('change');
              input.dispatchEvent(event);
           }
         }
      })
   });

   const ratingInputs= document.querySelectorAll(".js-review-input");
   ratingInputs.forEach(el => {
      el.addEventListener("change", () => {
         checkAllReviews();
      })
   });

   const checkAllReviews = () => {
      let isReviewed = true;

      ratingInputs.forEach(el => {
         if(!el.value) {
            isReviewed = false;
         }
      });

      if(isReviewed) {
         document.querySelector(".product-review__text").style.display = "none";
         document.querySelector(".product-review__button").style.display = "block";
      } else {
         document.querySelector(".product-review__text").style.display = "block";
         document.querySelector(".product-review__button").style.display = "none";
      }
   }
}
