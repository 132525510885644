const productSectionsTriggers = document.querySelectorAll(
  '.accordion p:first-of-type'
)

if (productSectionsTriggers) {
  let counter = 0
  const openClass = 'accordion--open'

  productSectionsTriggers.forEach((item) => {
    if (counter === 0 || counter === 1) {
      item.parentNode.classList.add(openClass)
    }

    item.addEventListener('click', (event) => {
      if (item.parentNode.classList.contains(openClass)) {
        item.parentNode.classList.remove(openClass)
      } else {
        item.parentNode.classList.add(openClass)
      }
    })

    counter++
  })
}
