const shippingForm = document.getElementById("js-shipping-form");

if(shippingForm) {

   shippingForm.addEventListener("submit", (event) => {
      if(document.querySelector(".shipping-page__shop-wrapper--open") && !document.querySelector(".js-shop-radio:checked")) {
         event.preventDefault();
         const y = document.getElementById("map").getBoundingClientRect().top + window.pageYOffset - 100;
         window.scrollTo({top: y, behavior: 'smooth'});

         alert(event.target.getAttribute("data-error"))

      } else {
         return true;
      }
   });

   const shippingInputs = document.querySelectorAll("input[name=shipping_id]");
   const shopsWrapper = document.querySelector(".shipping-page__shop-wrapper");

   if(shippingInputs.length) {
      shippingInputs.forEach((shipping) => {

         if(shipping.checked) {
            shipping.parentElement.parentElement.classList.add("shipping-page__option--active")
            
            if(shipping.getAttribute("module") !== "0") {
               shopsWrapper.classList.add("shipping-page__shop-wrapper--open");
            }
         }

         shipping.addEventListener("change", (event) => {
            document.querySelectorAll(".shipping-page__shop-wrapper").forEach((shops) => {
               shops.classList.remove("shipping-page__shop-wrapper--open")
            })

            document.querySelector(".shipping-page__option").classList.remove("shipping-page__option--active");

            if(event.target.checked) {
               event.target.parentElement.parentElement.classList.add("shipping-page__option--active")

               if(event.target.getAttribute("module") !== "0") {
                  const y = event.target.getBoundingClientRect().top + window.pageYOffset - 50;
                  window.scrollTo({top: y, behavior: 'smooth'});

                  console.log(y);

                  shopsWrapper.classList.add("shipping-page__shop-wrapper--open");
               }
            }
         });
      });
   }

   new Vue({
      el: '#js-shipping-map',
      data() {
         return {
            shops: [],
         }
      },
      mounted() {
         this.loadMap()

         shippingInputs.forEach((shipping) => {
            shipping.addEventListener("change", (event) => {
               if(event.target.getAttribute("module") !== "0") {
                  this.loadMap();
               }
            });
         });
      },
      methods: {
         generateInfoWindow(element) {
            return new google.maps.InfoWindow({
                     content: `<b>${element.getAttribute("data-name")}</b>
                              <p>${element.getAttribute("data-address")}</p>
                     `,
               });
         },
         loadMap() {
            if (!document.getElementById('map')) {
               return
            }

            var mapOptions = {
               disableDefaultUI: true,
               zoomControl: true
            }

            var map = new google.maps.Map(
               document.getElementById('map'),
               mapOptions
            )

            var latlngbounds = new google.maps.LatLngBounds()

            var infowindow = null;


            document.querySelectorAll(".js-shop-radio").forEach((shop) => {

               if(!shop.getAttribute("data-lat") || !shop.getAttribute("data-lon")) {
                  return null;
               }

               var latLng = new google.maps.LatLng(
                  parseFloat(shop.getAttribute("data-lat")),
                  parseFloat(shop.getAttribute("data-lon"))
               )
      
               latlngbounds.extend(latLng)
      
               var marker = new google.maps.Marker({
                  position: latLng,
                  map: map,
                  title: shop.getAttribute("data-name"),
                  animation: google.maps.Animation.DROP,
               })

               marker.addListener("click", () => {
                  if (infowindow) {
                     infowindow.close();
                  }

                  infowindow = this.generateInfoWindow(shop);
                  
                  shop.checked = true;

                  const parent = document.querySelector(".shipping-page__shops");
                  const y = shop.offsetTop - 20;
                  parent.scrollTo({top: y, behavior: 'smooth'});

                  infowindow.open(map, marker);
               });

               shop.addEventListener("change", () => {
                  if(shop.checked) {
                     if (infowindow) {
                        infowindow.close();
                     }

                     infowindow = this.generateInfoWindow(shop);
                     infowindow.open(map, marker);
                  }
               });
            });

            map.fitBounds(latlngbounds)
         },
      },
   })
}