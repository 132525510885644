const approveProductsTrigger = document.getElementById(
  'js-approve-open-products'
)

if (approveProductsTrigger) {
  approveProductsTrigger.addEventListener('click', () => {
    approveProductsTrigger.classList.toggle(
      'approve-page__products-trigger--open'
    )
    document
      .getElementById('js-approve-products')
      .classList.toggle('approve-page__products--open')
  })
}
