const basketActions = document.querySelectorAll(".js-basket-actions");
const basketForm = document.getElementById("js-basket-form");

if(basketActions.length) {

   basketActions.forEach((action) => {

      const input = action.querySelector("input");
      input.addEventListener("change", () => {
         basketForm.submit();
      });

      const removeButton = action.querySelector(".basket-page__remove");
      removeButton.addEventListener("click", () => {
         input.value = 0;
         basketForm.submit();
      });

      const minusButton = action.querySelector(".add-to-basket__amount-btn:first-of-type");
      minusButton.addEventListener("click", () => {
         input.value = parseInt(input.value) - 1;
         basketForm.submit();
      })

      const plusButton = action.querySelector(".add-to-basket__amount-btn:last-of-type");
      plusButton.addEventListener("click", () => {
         input.value = parseInt(input.value) + 1;
         basketForm.submit();
      })
   });

   const discountTrigger = document.querySelector(".basket-page__discount-trigger");
   const discountContent = document.querySelector(".basket-page__discount-content");
   discountTrigger.addEventListener("click", () => {
      discountTrigger.classList.toggle("basket-page__discount-trigger--open");
      discountContent.classList.toggle("basket-page__discount-content--open");
   })
   
}
